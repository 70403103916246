.requestDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rdTitle {
  flex: 0 0 100px;
  display: flex;
  background: linear-gradient(45deg, #fd7e14 0%, #fa5252 100%);
  border-radius: 0.25rem;
  height: 2.25rem;
}
.rdTitleText {
  position: relative;
  margin: auto;
  color: #fff;
  font-weight: 600;
  line-height: 1;
  font-size: 0.875rem;
}
.notSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.rdURI {
  flex: 1 1 auto;
}

.rdSendButton {
  flex: 0 0 100px;
}

.rest {
  width: 100%;
  height: 100%;
}

.restContentWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-drawer-and-page);
  height: calc(var(--screen-height) - var(--top-padding));
}

.resizableBox {
  background-color: #fd7e14;
}

.panelWrapper {
  width: calc(100% - var(--margin) - var(--margin));
  height: 100%;
  margin: var(--margin);
}

.firstPanel {
  /* background-color: blue; */
  width: 100%;
}

.sndPanel {
  /* background-color: purple; */
  width: 100%;
}

.inputBox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--row-gap);
}

.inputBoxElement {
  flex: 1 1 auto;
  margin: auto;
  padding: var(--padding-drawer-and-page);
  height: calc(100% - var(--top-padding));
}

.inputBoxElementCtrlButton {
  flex: 0 0 100px;
  margin: auto;
}

.textarea {
  width: 100%;
  height: calc(100% - var(--top-padding));
  resize: none;
}

.chatBox {
  display: flex;
  flex-direction: column;
  padding: var(--top-padding);
  gap: var(--row-gap);
  height: calc(100% - var(--top-padding) - var(--top-padding));
  overflow: scroll;
  background: linear-gradient(
    68.7deg,
    rgb(29, 173, 235) 13.2%,
    rgb(137, 149, 250) 29.8%,
    rgb(229, 109, 212) 48.9%,
    rgb(255, 68, 128) 68.2%,
    rgb(255, 94, 0) 86.4%
  );
  border-radius: 5px;
}

.sentMessage,
.receivedMessage {
  background-color: white;
  color: black;
  width: var(--chat-message-width);
  padding: var(--chat-message-padding);
  border-radius: 5px;
  white-space: pre-wrap;
}

.sentMessage {
  align-self: flex-end;
}

.eventMessage {
  box-shadow: var(--shadow);
  color: black;
  padding: var(--chat-message-padding);
  align-self: center;
  text-align: center;
  height: fit-content;
  border-radius: 50px;
}

:root {
  --chat-message-padding: 10px;
  --chat-message-width: 50%;
}

.tabs {
  height: calc(100% - var(--margin) - var(--margin));
}

.tab {
  height: calc(100% - var(--margin) - var(--margin));
}

.alert {
  display: flex;
  flex-direction: row;
  color: #ff9500;
  align-content: center;
  gap: 20px;
}
