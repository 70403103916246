.navbar, .navbar2 {
  width: rem(80px);
  height: rem(750px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #228be6;
}
.navbarMain {
  flex: 1;
  margin-top: rem(50px);
  
}

.navbar2 {
  width: 60px;
  height:100%;
}

.link {
  width: rem(50px);
  height: rem(50px);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover {
    background-color: #1c7ed6;
  }

  &[data-active] {
    &,
    &:hover {
      box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem, rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem;
      background-color: #fff;;
      color: #228be6;
    }
  }
}

.stack {
  gap:0;
  background-color: pink;
}