:root {
--columns : 3;
}

.cardClass {
  min-height: 200px;
  margin: 20px;
  background: radial-gradient(
    circle at 10% 20%,
    rgb(251, 221, 19) 0.1%,
    rgb(255, 153, 1) 90%
  );
}

.workbenchCardText {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 70px;
  color: white ;
  user-select: none;
  text-align: center;
;}

.grid{
  display: grid;
  row-gap: 50px;
  grid-template-columns: repeat(var(--columns), auto);
}