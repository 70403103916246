:root {
  --height-input-group: 50;
}
.drawer {
  background-color: whitesmoke;
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.drawerContentWrapper {
  padding: 0px var(--padding-drawer-and-page) 0px var(--padding-drawer-and-page);
  width: calc(100% - var(--left-right-padding)- var(--left-right-padding));
  height: 100%;
  margin: 0px var(--left-right-padding);
}

.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* height: calc(var(--height-input-group) * 1px); */
  height: 50px;
  min-height: 50px;
}

.inputArea {
  width: 220px;
  margin: var(--margin);
}

.inputButton {
  width: 200px;
  margin: auto;
  -webkit-justify-content: left;
  justify-content: left;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.delete {
  margin: var(--margin);
}

.historyGroup {
  display: flex;
  flex-direction: row;
}

.headerDrawer {
  background-color: #87b6e0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
}

.drawerTitle {
  margin: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.closeDrawer {
  color: white;
}

.spaceGroteskFont {
  font-family: "Space Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
}

.addBar {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.inputAddSearch,
.addButton {
  margin: auto;
}

.selectedInputGroup {
  border-radius: 0.25rem;
  /* color: white;
  background-color: #228be6; */
  color: #228be6;
  font-weight: bold;
}

.selectedIcon {
  -webkit-justify-content: left;
  justify-content: left;
  margin: auto;
}

.collectionTree {
  overflow: scroll;
  height: calc(var(--screen-height) - (3 * var(--top-padding)));
}

.directoryRow {
  display: flex;
  flex-direction: row;
}

.treeElement {
  flex: 1 1 auto;
}

.iconElement {
  margin: auto;
}

.treeNavLink span[data-rotate="true"] {
  transform: rotate(0deg) !important;
}

.treeNavLink[data-expanded="true"] .arrow {
  transform: rotate(90deg) !important;
}

.infScroll {
  /* height: 700px; */
  overflow: scroll;
}
