.headerArea {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 20px;
}

.nameBox {
  margin: auto;
  flex: 1 1 auto;
}
.actionButton {
  margin: auto;
  flex: 0 0 50px;
}
.actionButton, .actionButtonAdd {
    &:disabled,
    &[data-disabled] {
      border-color: transparent;
      background-color: transparent;
    }
  }

.envGrid {
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 10px;

}
.envGridHeader {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.envGridHeaderInput {
  margin: auto;
  flex: 1 1 auto;
}
.envGridHeaderButton {
  margin: auto;
  flex: 0 0 50px;
}

.envGridRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.envGridRowElement {
  flex: 1 1 auto;
}

