.wrapper {
  display: flex;
  margin: 0px;
  gap: 30px;
  height: 100%;
}

.menu {
  display: inline;
  flex-grow: 1;
}

.drawer {
  display: inline;
}
