/* ------------------------------CSS REFACTOR-------------------- */
:root {
  --padding-drawer-and-page: var(--top-padding) var(--left-right-padding) 0px
    var(--left-right-padding);
  --top-padding: 30px;
  --left-right-padding: 10px;
  --row-gap: 20px;
  --screen-height: 100vh;
  --margin: max(1%, 10px);
}

.rest {
  width: 100%;
  height: 100%;
}

.restContentWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-drawer-and-page);
  height: calc(var(--screen-height) - var(--top-padding));
}

.requestDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rdURI {
  flex: 1 1 auto;
}

.rdSendButton,
.rdHttpMethod {
  flex: 0 0 100px;
}

.rdTitle {
  flex: 0 0 100px;
  display: flex;
  background: linear-gradient(45deg, #fd7e14 0%, #fa5252 100%);
  border-radius: 0.25rem;
  height: 2.25rem;
}
.rdTitleText {
  position: relative;
  margin: auto;
  color: #fff;
  font-weight: 600;
  line-height: 1;
  font-size: 0.875rem;
}
.tabsGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  gap: 20px;
}

.tabs {
  width: 100%;
  margin: 10px 0px 10px 0px;
  color: gray;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tab {
  width: 100%;
  height: calc(100% - var(--top-padding) - 5px);
  overflow: scroll;
}

.requestBodyTextArea {
  margin: var(--margin);
  height: calc(100% - var(--top-padding));
}

.tabContentWrapper {
  display: flex;
  flex-direction: column;
  padding: var(--padding-drawer-and-page);
  gap: var(--row-gap);
}

.scrollableContent {
  height: 75vh;
  overflow: scroll;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inputArea {
  width: 100%;
  margin: var(--margin);
}

.delete {
  margin: var(--margin);
}

.httpResponseCode {
  pointer-events: none;
  position: absolute;
  right: var(--top-padding);
  margin: 6px;
  color: rgb(4, 131, 170);
}

.notSelectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
