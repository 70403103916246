.collectionDetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameInput {
  flex: 1 1 auto;
}

.updateButton {
  flex: 0 0 100px;
}

/****************/
.fileName {
  margin: var(--margin);
  border: 0px;
  cursor: url(../../public/pencil.svg) 0 30, auto;

  &:disabled {
    cursor: default;
  }
}
.folderName {
  margin: var(--margin);
  cursor: url(../../public/pencil.svg) 0 30, auto;
}

.saveDialogHeader {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: var(--margin);
}

.saveInCollectionDialog {
  display: flex;
  flex-direction: column;
}

.directoryPath {
  background-color: rgb(228, 228, 228);
  margin: calc(var(--margin) / 2);
  padding: var(--margin);
  border-radius: 5px;
  overflow: scroll;
  scrollbar-width: thin;
}

.newDirectoryBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.folderBox {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: scroll;
  padding: var(--margin);
  width: calc(100% - (2 * var(--margin)));
}

.emptyDirectory {
  margin: auto;
  width: fit-content;
  color: grey;
  font-style: italic;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--margin);
}

.button {
  width: 100px;
}

/****/
